import { Link } from "gatsby"
import * as React from "react"
import styled from "styled-components"
import bgImage from "../images/homepage/hero_banner_bg.png"
import { Underline, ButtonPrimary } from "./common/styledComponentsHelper"
import { secondaryColor, successColor } from "../static/styles/colors"
import Image from "./common/heroImage"
import { BsPeopleFill } from "@react-icons/all-files/bs/BsPeopleFill"
import { BsLaptop } from "@react-icons/all-files/bs/BsLaptop"

const HeroSection = ({
  desktopImg,
  mobileImg,
  headlineText,
  responsiveHeadline,
  subheadlineText,
  captionText,
  buttons,
}) => {
  let buttonContent = ""
  if (buttons) {
    buttonContent = (
      <div className="container mt-xxl-5 mb-lg-0 mb-5">
        <div className="row">
          <div className="col-sm-6 col-12 mb-sm-0 mb-3">
            <ButtonPrimary>
              <Link to="/seminare" className="align-items-center d-flex">
                <BsLaptop className="me-3" color={successColor} />
                Seminare
              </Link>
            </ButtonPrimary>
          </div>
          <div className="col-sm-6 col-12">
            <ButtonSecondary>
              <Link
                to="/kaufmaennische_begleitung"
                className="align-items-center d-flex"
              >
                <BsPeopleFill className="me-3" />
                Beratung
              </Link>
            </ButtonSecondary>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container-fluid overflow-hidden">
      <BgWallpaper className="row flex-lg-row-reverse align-items-center">
        <div className="col-lg-7">
          <div className="container pt-4 pb-4">
            <Underline className="display-4 fw-bold d-none d-sm-block lh-1 mb-3 text-uppercase text-light mb-3">
              {headlineText}
            </Underline>
            <Underline className="display-4 fw-bold lh-1 d-sm-none mb-3 text-uppercase text-light mb-3">
              {responsiveHeadline}
            </Underline>
            <p className="display-4 text-uppercase text-light pt-4 mb-4">
              {subheadlineText}
            </p>
            <p className="fs-5 text-uppercase text-light pt-3">{captionText}</p>
          </div>
          {buttonContent}
        </div>
        <div className="col-lg-5 p-0">
          <Image
            alt="Hero Banner"
            filenameDesktop={desktopImg}
            filenameMobile={mobileImg}
            style={{
              filter: `drop-shadow(10px 0px 25px ${successColor})`,
            }}
          />
        </div>
      </BgWallpaper>
    </div>
  )
}

const BgWallpaper = styled.div`
  background-image: url(${bgImage});
  background-repeat: no-repeat;
  background-size: cover;
`
const ButtonSecondary = styled.button`
  font-size: 1.6rem;
  border: 1px solid ${successColor};
  background-color: transparent;
  padding: 15px 25px;
  text-transform: uppercase;
  :hover {
    -moz-box-shadow: inset 0 0 8px ${successColor};
    -webkit-box-shadow: inset 0 0 8px ${successColor};
    box-shadow: inset 0 0 8px ${successColor};
    background-color: ${secondaryColor};
    & > a {
      color: ${successColor};
    }
    & > a > svg {
      color: ${successColor};
    }
  }
  & > a > svg {
    color: white;
    height: 2rem;
    width: 2rem;
  }
  & > a {
    text-decoration: none;
    color: white;
  }
  @media screen and (max-width: 1400px) {
    font-size: 1rem;
    & > a > svg {
      height: auto;
      width: auto;
    }
  }
`

export default HeroSection
