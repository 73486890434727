import * as React from "react"
import Layout from "../components/common/layout"
import { Separator } from "../components/common/styledComponentsHelper"
import HeroSection from "./../components/hero-section"

const ImpressumHeadlineStyle = "text-uppercase text-bold fw-bold"

export const Head = () => (
  <>
    <title>Impressum | Fachhandwerk360</title>
    <meta
      name="facebook-domain-verification"
      content="xooo742lkxtkqa24ish1wyf2ppfc4e"
    />
  </>
)

const Impressum = () => (
  <Layout>
    <HeroSection
      desktopImg="impressum_banner_desktop.png"
      mobileImg="impressum_banner_mobile.png"
      headlineText=""
      responsiveHeadline=""
      subheadlineText=""
      captionText={
        <div className="container">
          <h1 className={ImpressumHeadlineStyle}>Impressum</h1>
          <Separator />
          <h2>Unternehmen</h2>
          <p>
            Fachhandwerk360 Dreier & Herber GbR
            <br />
            Gesellschafter: Rainer Dreier, Martin Herber
            <br />
            Hauptsitz: Rheinstraße 28, 55432 Niederburg
            <br />
            Zweigstelle: Leutragraben 1, 07743 Jena
            <br />
            UST-ID: DE346 039 470
            <br />
          </p>
          <Separator />
          <h2>Kontakt</h2>
          <p>
            Telefon: +49 6744 9491087
            <br />
            Unternehmenswebsite: www.fachhandwerk360.de
            <br />
            E-Mail: info@fachhandwerk360.de
          </p>
        </div>
      }
    />
  </Layout>
)

export default Impressum
